
import React             from 'react';
import { navigate }      from 'gatsby';
import { Auth0Provider } from '@auth0/auth0-react';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "@popperjs/core/dist/umd/popper.min.js";

const onRedirectCallback = (appState) => {
    // Use Gatsby's navigate method to replace the url
    navigate(appState?.returnTo || '/', { replace: true });
};

export const wrapRootElement = ({ element }) => {
    return (
        <Auth0Provider
            domain={process.env.AUTH0_DOMAIN}
            clientId={process.env.AUTH0_CLIENTID}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
        >
            {element}
        </Auth0Provider>
    );
};